<template>
  <div class="nav">
    <div class="groups">
      <label for="env">Environment</label>
      <select v-model="env" name="" id="env">
        <option value="">Select</option>
        <option value="dev">DEV</option>
        <option value="uat">UAT</option>
        <option value="pharm-uat">PAHRM-UAT</option>
        <option value="preprod">PRE-PROD</option>
        <option value="prd">PRODUCTION</option>
      </select>
    </div>
    <div class="groups">
      <label for="hub">Hub</label>
      <select v-model="hub" name="" id="hub">
        <option value="">Select</option>
        <option value="phub">PHUB</option>
        <option value="ophub">OPHUB</option>
        <option value="chs">CHS</option>
        <option value="com">COMM</option>
        <option value="comm_service">COMM_SERVICE</option>
        <option v-if="getEnv != 'pharm-uat'" value="bupa">BUPA</option>
        <option v-if="getEnv != 'pharm-uat'" value="analytics">ANALYTICS</option>
        <option value="socket">SOCKET</option>
        <option value="fleet">FLEET</option>
        <option value="pharm">PHARMACY</option>
        <option v-if="getEnv != 'pharm-uat' && getEnv != 'uat'" value="payment">PAYMENT</option>
        <option value="payment">PAYMENT_HUB</option>
        <option value="family">FAMILY_DOC</option>
        <option value="caregiver">CARE_GIVER</option>
        <option value="sigma">SIGMA</option>
        <option value="employee">EMPLOYEE</option>
        <option value="auth">AUTH</option>
        <option value="nurse">NURSE</option>
        <option value="chat">CHAT</option>
        <option value="lab">LAB</option>
        <option value="waseel">WASEEL</option>
        <option value="bloodDonation">BLOOD_DONATION</option>
        <option value="nurseVisit">NURSE_VISIT</option>
        <option value="physiotherapist">PHYSIOTHERAPIST</option>
        <option value="instantTeleComm">ITC</option>
        <option value="corporateWellness">CORPORATE_WELLNESS</option>
        <option value="physioPro">PHYSIO_PRO</option>
        <option value="emailSms">EMAIL_SMS</option>
        <option value="kidsVaccination">KIDS_VACCINATION</option>
        <option value="thirdPartyApi">THIRD_PARTY_API</option>
        <option value="pres">PRESCRIPTION</option>
        <option value="user">USER</option>
        <option value="telemedicine">TELEMEDICINE(TMS)</option>
        <option value="homevisit">HOME_VIST_DOC</option>
        <option value="notification">NOTIFICATION</option>
        <option value='labServices'>LABORATORY_SERVICES(LBS)</option>
        <option value="weightLoss" v-if="getEnv != 'prd'">WEIGHT_LOSS</option>
        <option value="vitaminIV">VITAMIN_IV</option>
        <option value="dispense">DISPENSE</option>
        <option value="invoice">INVOICE</option>
        <option value="apntServices">APPOINTMENT_SERVICES</option>
      </select>
    </div>
    <div v-if="getEnv == 'prd'" class="groups">
      <label for="server">Environment</label>
      <select v-model="ser" name="" id="server">
        <option value="">Select</option>
        <option value="1" v-if="getHub != 'thirdPartyApi'">AWS-1</option>
        <option value="1" v-if="getHub == 'thirdPartyApi'">ALIBABA-1</option>
        <option
          v-if="getHub != 'com' && getHub != 'comm_service' && getHub != 'socket' && getHub != 'analytics' && getHub != 'sigma' && getHub != 'employee' && getHub != 'nurse' && getHub != 'chat' && getHub != 'lab' && getHub != 'waseel' && getHub != 'bloodDonation' && getHub !='corporateWellness'&& getHub !='physioPro'&& getHub !='instantTeleComm'&& getHub !='emailSms' && getHub !='kidsVaccination' && getHub !='thirdPartyApi' && getHub !='pres' && !['user','telemedicine','homevisit','notification','labServices','vitaminIV','dispence','nurseVisit','invoice','apntServices'].includes(getHub)" 
          value="2">AWS-2</option>
        <option
          v-if="getHub != 'com' && getHub != 'comm_service' && getHub != 'socket' && getHub != 'analytics' && getHub != 'sigma' && getHub != 'fleet' && getHub != 'pharm' && getHub != 'payment' && getHub != 'employee' && getHub != 'auth' && getHub != 'nurse' && getHub != 'chat' && getHub != 'lab' && getHub != 'waseel' && getHub != 'bloodDonation' && getHub !='corporateWellness'&&getHub !='physioPro'&& getHub !='instantTeleComm'&& getHub !='emailSms' && getHub !='kidsVaccination' && getHub !='thirdPartyApi'&& getHub !='pres' && !['user','telemedicine','homevisit','notification','labServices','vitaminIV','dispence','nurseVisit','invoice','apntServices'].includes(getHub)"
          value="3">
          AWS-3</option>
        <option
          v-if="getHub != 'bupa' && getHub != 'com' && getHub != 'comm_service' && getHub != 'socket' && getHub != 'analytics' && getHub != 'sigma' && getHub != 'fleet' && getHub != 'pharm' && getHub != 'payment' && getHub != 'waseel' && getHub !='thirdPartyApi'&& getHub !='pres' && !['user','telemedicine', 'chs', 'auth','dispence'].includes(getHub)"
          value="4">DO-1</option>
        <option
          v-if="getHub != 'bupa' && getHub != 'com' && getHub != 'comm_service' && getHub != 'socket' && getHub != 'analytics' && getHub != 'sigma' && getHub != 'fleet' && getHub != 'pharm' && getHub != 'payment' && getHub != 'employee' && getHub != 'auth' && getHub != 'nurse' && getHub != 'chat' && getHub != 'lab' && getHub != 'waseel' && getHub != 'bloodDonation' && getHub !='corporateWellness' && getHub !='kidsVaccination'&&getHub !='physioPro'&& getHub !='instantTeleComm' && getHub !='emailSms' && getHub !='thirdPartyApi'&& getHub !='pres' && !['user','telemedicine','homevisit','notification','chs','labServices','vitaminIV','dispence','nurseVisit','invoice','apntServices'].includes(getHub)"
          value="5">DO-2</option>
        <option
          v-if="getHub != 'bupa' && getHub != 'com' && getHub != 'comm_service' && getHub != 'socket' && getHub != 'analytics' && getHub != 'sigma' && getHub != 'fleet' && getHub != 'pharm' && getHub != 'payment' && getHub != 'employee' && getHub != 'auth' && getHub != 'nurse' && getHub != 'chat' && getHub != 'lab' && getHub != 'waseel' && getHub != 'bloodDonation' && getHub !='corporateWellness'&&getHub !='physioPro'&& getHub !='instantTeleComm'&& getHub !='emailSms' && getHub !='kidsVaccination' && getHub !='thirdPartyApi'&& getHub !='pres' && !['user','telemedicine','homevisit','notification','chs','labServices','vitaminIV','dispence','nurseVisit','invoice','apntServices'].includes(getHub)"
          value="6">DO-3</option>
          <option
          v-if="['chs'].includes(getHub)"
          value="7">AWS-4</option>
          <option
          v-if="['chs'].includes(getHub)"
          value="8">AWS-5</option>
        <option value="all">ALL</option>
      </select>
    </div>
    <div v-if="getServer != 'all'" class="groups">
      <button :disabled="getLoading" @click="getData()">
        {{ getLoading ? "LOADING..." : "SUBMIT" }}
      </button>
    </div>
    <!-- v-if="getDashboardData.length" -->
    <div v-if="getDashboardData.length" class="last-ip">
      <input placeholder="Search" v-model="search" type="text">
      <p @click="clearSearch" class="btn">x</p>
      <p>{{ getFilteredData.length }} / {{ getDashboardData.length }}</p>
    </div>

  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "DataNav",
  data() {
    return {
      endpoint: [],
      // env: null,
      searchKey: null
    };
  },
  watch: {
    // env() {
    //   this.setEndpoint([]);
    //   this.setEnv(this.env);
    // },
  },
  computed: {
    ...mapGetters("data", {
      getEnv: "getEnv",
      getHub: "getHub",
      getLoading: "getLoading",
      getEndpoint: "getEndpoint",
      getServer: "getServer",
      getDashboardData: "getDashboardData",
      getSearchKey: "getSearchKey",
      getFilteredData: "getFilteredData"
    }),
    hub: {
      get() {
        return this.getHub
      },
      set(newV) {
        this.setEndpoint([])
        this.setHub(newV)
      }
    },
    ser: {
      get() {
        return this.getServer
      },
      set(newV) {
        this.setEndpoint([])
        this.setServer(newV)
      }
    },
    env: {
      get() {
        return this.getEnv
      },
      set(newV) {
        this.setEndpoint([])
        this.setEnv(newV)
      }
    },
    search: {
      get() {
        return this.getSearchKey
      },
      set(newV) {
        this.setSearchKey(newV)
        let result = this.getDashboardData.filter(r => r.name.match(newV)) || []
        this.$store.commit("data/SET_FILTERED_DATA", result);
      }
    }

  },

  methods: {
    ...mapMutations("data", {
      setEnv: "SET_ENV",
      setHub: "SET_HUB",
      setServer: "SET_SERVER",
      setEndpoint: "SET_ENDPOINT_EMPTY",
      setSearchKey: "SET_SEARCH_KEY",
      setFilteredData: "SET_FILTERED_DATA"
    }),
    async getData() {
      try {
        let e = "";
        if (this.getEnv === "prd") {
          e = this.getEnv + this.getServer + this.getHub;
        } else {
          e = this.getEnv;
        }
        this.$store.commit("data/SET_ENDPOINT_EMPTY", []);
        let result = await this.$store.dispatch("data/getData", {
          env: e,
          hub: this.getHub,
          endpoint: this.getEndpoint,
        });
        // console.log(result);
        if (result && result.status == 1001) {
          alert(result.message || "Something went wrong/plz login again");
        } else if (!result || result.status == 1005) {
          this.$store.commit("data/SET_DASHBOARD_DATA", []);
          this.$store.commit("data/SET_ENDPOINT_EMPTY", []);
          this.$store.commit("data/SET_ENV", "");
          this.$store.commit("data/SET_HUB", "");
          this.$store.commit("data/SET_SERVER", "");
          this.$store.dispatch("auth/logout");
          this.$router.push({ path: "Auth" });
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    },
    clearSearch() {
      this.setSearchKey(null)
      this.setFilteredData([])
    }

  },
};
</script>

<style scoped>
.nav {
  color: #2c3e50;
  width: 90%;
  margin: 0 auto;
  margin-top: 25px;
  height: 100px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  box-shadow: -10px -10px 40px #f4f4f4, 10px 10px 40px #bebebe;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.groups {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  margin: 5px 18px;
  font-size: 13px;
}

select,
button {
  margin: 0 15px;
  padding: 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  /* border-bottom: 1px solid #bebebe; */
  width: 200px;
  box-shadow: -3px -3px 15px #f4f4f4, 4px 4px 15px #bebebe;
}

select option {
  font-size: 17px;
}

button {
  max-width: 100px;
  box-shadow: none;
  margin-top: 25px;
}


.last-ip {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 50px;
  width: 80%;
}

.last-ip input {
  width: 60%;
  max-width: 350px;
  padding: 10px;
  background-color: #e0e0e0;
  border: none;
  border-bottom: 1px solid #bebebe;
  outline: none;
  font-size: 15px;
}

.last-ip p {
  display: block;
  margin: 0 10px;
}

p.btn {
  max-width: 30px;
  padding: 7px;
  margin-left: -30px;
  margin-bottom: 12px;
  background-color: #efefef;
  border-radius: 7px;
  color: #2c3e50;
}

button:hover,
.btn:hover {
  background-color: #2c3e50;
  color: #f4f4f4;
}
</style>